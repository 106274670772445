import type {FC} from 'react';
import React from 'react';
import {ToggleButton, ToggleButtonGroup, Tooltip} from '@mui/material';
import {
  useEnvironmentSession,
  useMerchant,
} from '@local/frontend/libs/trpc/trpc';
import {useChangeSessionEnvironment} from '@local/frontend/libs/api/session';
import {ModalName} from '@local/frontend/libs/modals/ModalName';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import {Env} from '../../../@types/env';
import config from '../../../config';
import {hasPaidBillingPlan} from '../../../utils/merchant-utils';

export const EnvironmentToggle: FC<React.PropsWithChildren<unknown>> = () => {
  const {openModal} = useCustomModals();
  const {data: merchant, isInitialLoading: isMerchantLoading} = useMerchant();
  const {data: environment, isInitialLoading: isEnvironmentInitialLoading} =
    useEnvironmentSession();

  const environmentMutation = useChangeSessionEnvironment();

  const handleToggle = (
    _event: React.MouseEvent<HTMLElement>,
    newEnvironment: Env | null
  ) => {
    if (merchant) {
      if (newEnvironment === Env.PRODUCTION && !hasPaidBillingPlan(merchant)) {
        openModal(ModalName.BILLING_PLANS, {merchant});
        // early return to prevent switching to live if merchant not on a paid or custom plan.
        return;
      }

      if (newEnvironment) {
        environmentMutation.mutate(newEnvironment);
      }
    }
  };

  const isDisabled =
    isMerchantLoading ||
    isEnvironmentInitialLoading ||
    environmentMutation.isLoading ||
    config.ENV === Env.DEVELOPMENT;

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        config.ENV === Env.DEVELOPMENT
          ? 'Feature disabled in developer mode'
          : ''
      }
    >
      <ToggleButtonGroup
        sx={{height: '20px'}}
        value={environment ?? Env.SANDBOX}
        exclusive
        onChange={handleToggle}
        disabled={isDisabled}
        aria-label="environment"
      >
        <ToggleButton
          disabled={isDisabled}
          key="toggle-sandbox-button"
          value={Env.SANDBOX}
          aria-label="sandbox"
          color="warning"
        >
          Sandbox
        </ToggleButton>
        <ToggleButton
          disabled={isDisabled}
          key="toggle-production-button"
          value={Env.PRODUCTION}
          aria-label="live"
          color="primary"
        >
          Live
        </ToggleButton>
      </ToggleButtonGroup>
    </Tooltip>
  );
};
