export enum GatewayDisplayNames {
  AYDEN = 'Ayden',
  AUTHORIZENET = 'Authorize Net',
  BRAINTREE = 'Braintree',
  BSPAYONE = 'Bspayone',
  CARDCONNECT = 'Card Connect',
  CHECKOUT = 'Checkout.com',
  ECOMMPAY = 'Ecommpay',
  GLOBALPAYMENTS = 'Global Payments',
  MOLLIE = 'Mollie',
  NMI = 'Nmi',
  OPAYO = 'Opayo',
  PAYPAL = 'Paypal',
  PAYSAFE = 'Paysafe',
  PAYTOMORROW = 'Paytomorrow',
  QUICKBOOKS = 'Quickbooks',
  SHIFT4 = 'Shift 4',
  SQUARE = 'Square',
  STRIPE = 'Stripe',
  TRUSTPAYMENTS = 'Trust Payments',
}
