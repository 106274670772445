import type {FC} from 'react';
import React, {useEffect, useState} from 'react';
import {Alert, Portal, Skeleton, Stack} from '@mui/material';
import type {PublicConnectDTO} from '@local/backend/@types/updated-api-types/gateways/Gateway';
import {Gateway} from '@local/backend/@types/updated-api-types/gateways/Gateway';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {Currency} from '@handsin/money';
import LoadingButton from '@local/frontend/components/atoms/buttons/LoadingButton';
import {useQueryClient} from '@tanstack/react-query';
import {getQueryKey} from '@trpc/react-query';
import {useNotification} from '@local/frontend/hooks/useNotification';
import {
  trpc,
  useCreatePaymentGatewayConnections,
  useCreateShuttleDeepLink,
  useMerchant,
  useUpdatePaymentGatewayConnection,
} from '../../../../../libs/trpc/trpc';
import type {GatewayConfigurationProps} from './common/GatewayConfigurationProps';
import GenericFormSection from './common/GenericFormSection';

const shuttleConnectSchema = yup.object({
  name: yup.string().optional(),
  supportedCurrencies: yup
    .array(yup.mixed<Currency>().oneOf(Object.values(Currency)).required())
    .optional(),
});

interface ShuttleGatewaysIframeProps {
  shuttleDeepLinkObj?: {deep_link?: {id?: string}};
}

const ShuttleGatewaysIframe: FC<
  React.PropsWithChildren<ShuttleGatewaysIframeProps>
> = ({shuttleDeepLinkObj}) => {
  useEffect(() => {
    const shuttle = (window as {Shuttle?: {bind: () => void}})?.Shuttle;
    if (!shuttle) {
      throw new Error('Shuttle.js failed to load properly');
    }
    shuttle.bind();
  }, [shuttleDeepLinkObj]);

  return (
    <div
      data-shuttle-embed={shuttleDeepLinkObj?.deep_link?.id}
      style={{
        width: '95%',
        margin: 'auto',
      }}
    />
  );
};

const ShuttleConnectConfiguration: FC<
  React.PropsWithChildren<GatewayConfigurationProps>
> = ({actionAreaRef, gatewayDisplayName, setConnectRecord}) => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();

  const connectShuttleMutation = useCreatePaymentGatewayConnections({
    onSuccess: async newConnectRecord => {
      openNotification({
        message: 'Successfully connected to Shuttle',
        severity: 'success',
      });
      await queryClient.invalidateQueries(getQueryKey(trpc.connections.list));
      setConnectRecord(newConnectRecord);
    },
    onError: (error, service) => {
      openNotification({
        message:
          error.data?.axiosError?.response?.data.detail ??
          `Failed to connect to ${service.gateway}`,
        severity: 'error',
      });
    },
  });

  const {
    formState: {isSubmitting, isValid},
    ...formMethods
  } = useForm<yup.InferType<typeof shuttleConnectSchema>>({
    mode: 'all',
    resolver: yupResolver(shuttleConnectSchema),
    defaultValues: {
      name: undefined,
      supportedCurrencies: [],
    },
  });

  const onSubmit = (formData: yup.InferType<typeof shuttleConnectSchema>) => {
    connectShuttleMutation.mutate({
      gateway: Gateway.SHUTTLE,
      name: formData.name,
      supportedCurrencies: formData.supportedCurrencies,
    });
  };

  return (
    <Stack direction="column">
      <GenericFormSection control={formMethods.control} />
      <Portal container={() => actionAreaRef}>
        <LoadingButton
          onClick={formMethods.handleSubmit(onSubmit)}
          variant="contained"
          loading={isSubmitting}
          disabled={isSubmitting || !isValid}
        >
          {gatewayDisplayName ? `Connect ${gatewayDisplayName}` : 'Connect'}
        </LoadingButton>
      </Portal>
    </Stack>
  );
};

const ShuttleUpdateConfiguration: FC<
  React.PropsWithChildren<
    GatewayConfigurationProps & {connectRecord: PublicConnectDTO}
  >
> = ({actionAreaRef, connectRecord, setConnectRecord}) => {
  const queryClient = useQueryClient();
  const {open: openNotification} = useNotification();
  const [shuttleDeepLinkObj, setShuttleDeepLinkObj] = useState<{
    deep_link?: {id?: string};
  }>();
  const {data: merchant} = useMerchant();
  const createShuttleDeepLinkMutation = useCreateShuttleDeepLink({
    onSuccess: shuttleDeepObj => setShuttleDeepLinkObj(shuttleDeepObj),
  });

  const updateShuttleMutation = useUpdatePaymentGatewayConnection({
    onSuccess: async newConnectRecord => {
      openNotification({
        message: 'Successfully connected to Shuttle',
        severity: 'success',
      });
      await queryClient.invalidateQueries(getQueryKey(trpc.connections.list));
      setConnectRecord(newConnectRecord);
    },
    onError: (error, service) => {
      openNotification({
        message:
          error.data?.axiosError?.response?.data.detail ??
          `Failed to connect to ${service.gateway}`,
        severity: 'error',
      });
    },
  });

  useEffect(() => {
    if (!merchant || !connectRecord) return;
    createShuttleDeepLinkMutation.mutate({
      instanceId: connectRecord.gatewayId,
      params: {
        deep_link: {
          type: 'gateway-setup',
          user: {
            alt_key: merchant.id,
            name: merchant.name,
          },
        },
      },
    });
  }, [merchant]);

  const {
    formState: {isDirty, isSubmitting, isValid},
    ...formMethods
  } = useForm<yup.InferType<typeof shuttleConnectSchema>>({
    mode: 'all',
    resolver: yupResolver(shuttleConnectSchema),
    defaultValues: {
      name: '',
      supportedCurrencies: [],
    },
  });

  useEffect(() => {
    formMethods.reset({
      name: connectRecord.name,
      supportedCurrencies: connectRecord.supportedCurrencies,
    });
  }, [connectRecord]);

  const onSubmit = (formData: yup.InferType<typeof shuttleConnectSchema>) => {
    updateShuttleMutation.mutate({
      gateway: connectRecord.gatewayName,
      gatewayId: connectRecord.gatewayId,
      updateParams: {
        name: formData.name,
        supportedCurrencies: formData.supportedCurrencies,
      },
    });
  };

  return (
    <Stack spacing={2}>
      <GenericFormSection control={formMethods.control} />
      {createShuttleDeepLinkMutation.isLoading ? (
        <Skeleton variant="rectangular" width="100%" height="100%" />
      ) : (
        <ShuttleGatewaysIframe shuttleDeepLinkObj={shuttleDeepLinkObj} />
      )}
      {createShuttleDeepLinkMutation.error && (
        <Alert severity="error">
          Oops...Something went wrong! Please try again.
        </Alert>
      )}
      <Portal container={() => actionAreaRef}>
        <LoadingButton
          onClick={formMethods.handleSubmit(onSubmit)}
          variant="outlined"
          loading={isSubmitting || createShuttleDeepLinkMutation.isLoading}
          disabled={
            !isDirty ||
            isSubmitting ||
            !isValid ||
            createShuttleDeepLinkMutation.isLoading
          }
        >
          Update
        </LoadingButton>
      </Portal>
    </Stack>
  );
};

const ShuttleConfiguration: FC<
  React.PropsWithChildren<GatewayConfigurationProps>
> = ({connectRecord, ...props}) => {
  if (!connectRecord) {
    return <ShuttleConnectConfiguration {...props} />;
  }

  return (
    <ShuttleUpdateConfiguration {...props} connectRecord={connectRecord} />
  );
};

export default ShuttleConfiguration;
