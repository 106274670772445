import ReviewModal from '@local/frontend/components/modals/ReviewModal';
import AddGroupPaymentCustomerModal from '@local/frontend/components/modals/AddGroupPaymentCustomerModal/AddGroupPaymentCustomerModal';
import AddPaymentMethodModal from '@local/frontend/components/modals/AddPaymentMethodModal';
import BillingPlansModal from '@local/frontend/components/modals/BillingPlansModal';
import CancelBillingPlanModal from '@local/frontend/components/modals/CancelBillingPlanModal';
import CreateWebhookModal from '@local/frontend/components/modals/CreateWebhookModal';
import EditCustomerModal from '@local/frontend/components/modals/EditCustomerModal/EditCustomerModal';
import ManageWebhookModal from '@local/frontend/components/modals/ManageWebhookModal';
import MultiCardReviewModal from '@local/frontend/components/modals/MultiCardReviewModal/MultiCardReviewModal';
import RefundPaymentModal from '@local/frontend/components/modals/RefundPaymentModal/RefundPaymentModal';
import ReviewCheckoutModal from '@local/frontend/components/modals/ReviewCheckoutModal';
import UpdateCustomerModal from '@local/frontend/components/modals/UpdateCustomerModal';
import UpdateBillingDetailsModal from '@local/frontend/pages/dashboard/billing/components/UpdateBillingDetailsModal';
import InviteNewTeamMemberModal from '@local/frontend/pages/dashboard/settings/team/modals/InviteNewTeamMemberModal';
import type {DialogProps} from '@mui/material';
import SkipConnectGatewayModal from '@local/frontend/pages/user/onboarding/modals/SkipConnectGatewayModal';
import ConnectGatewayModal from '@local/frontend/pages/user/onboarding/modals/ConnectGatewayModal/ConnectGatewayModal';
import {ModalName} from './ModalName';

export const modalConfig = {
  [ModalName.REVIEW]: {
    name: ModalName.REVIEW,
    defaultProps: {
      maxWidth: 'xl',
      fullWidth: true,
      title: 'Review Group Payment',
    } as DialogProps,
    Element: ReviewModal,
  },
  [ModalName.REVIEW_CREATE_MULTI_CARD]: {
    name: ModalName.REVIEW_CREATE_MULTI_CARD,
    defaultProps: {
      maxWidth: 'lg',
      fullWidth: true,
      title: 'Review Multi Card Payment',
    } as DialogProps,
    Element: MultiCardReviewModal,
  },
  [ModalName.REVIEW_CHECKOUT]: {
    name: ModalName.REVIEW_CHECKOUT,
    defaultProps: {
      maxWidth: 'xl',
      fullWidth: true,
      title: 'Review Checkout',
    } as DialogProps,
    Element: ReviewCheckoutModal,
  },
  [ModalName.UPDATE_CUSTOMER]: {
    name: ModalName.UPDATE_CUSTOMER,
    defaultProps: {
      title: 'Update customer',
    } as DialogProps,
    Element: UpdateCustomerModal,
  },
  [ModalName.ADD_GROUP_PAYMENT_CUSTOMER]: {
    name: ModalName.ADD_GROUP_PAYMENT_CUSTOMER,
    defaultProps: {
      title: 'Add a customer',
    } as DialogProps,
    Element: AddGroupPaymentCustomerModal,
  },
  [ModalName.EDIT_CUSTOMER]: {
    name: ModalName.EDIT_CUSTOMER,
    defaultProps: {
      title: 'Edit Customer',
    } as DialogProps,
    Element: EditCustomerModal,
  },
  [ModalName.REFUND_PAYMENT]: {
    name: ModalName.REFUND_PAYMENT,
    defaultProps: {
      maxWidth: 'xs',
      fullWidth: true,
      title: 'Refund Payment',
    } as DialogProps,
    Element: RefundPaymentModal,
  },
  [ModalName.CREATE_WEBHOOK]: {
    name: ModalName.CREATE_WEBHOOK,
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
      title: 'Create Webhook',
    } as DialogProps,
    Element: CreateWebhookModal,
  },
  [ModalName.UPDATE_WEBHOOK]: {
    name: ModalName.UPDATE_WEBHOOK,
    defaultProps: {
      maxWidth: 'sm',
      fullWidth: true,
      title: 'Update Webhook',
    } as DialogProps,
    Element: ManageWebhookModal,
  },
  [ModalName.BILLING_PLANS]: {
    name: ModalName.BILLING_PLANS,
    defaultProps: {
      maxWidth: 'lg',
      title: 'Change billing plans',
    } as DialogProps,
    Element: BillingPlansModal,
  },
  [ModalName.CANCEL_BILLING_PLAN]: {
    name: ModalName.CANCEL_BILLING_PLAN,
    defaultProps: {
      maxWidth: 'md',
      fullWidth: true,
      title: 'Cancel billing plan',
    } as DialogProps,
    Element: CancelBillingPlanModal,
  },
  [ModalName.ADD_PAYMENT_METHOD]: {
    name: ModalName.ADD_PAYMENT_METHOD,
    defaultProps: {
      maxWidth: 'md',
      fullWidth: true,
      title: 'Add a card',
    } as DialogProps,
    Element: AddPaymentMethodModal,
  },
  [ModalName.UPDATE_BILLING_DETAILS]: {
    name: ModalName.UPDATE_BILLING_DETAILS,
    defaultProps: {
      maxWidth: 'md',
      fullWidth: true,
      title: 'Billing Details',
    } as DialogProps,
    Element: UpdateBillingDetailsModal,
  },
  [ModalName.INVITE_NEW_TEAM_MEMBER]: {
    name: ModalName.INVITE_NEW_TEAM_MEMBER,
    defaultProps: {
      maxWidth: 'md',
      title: 'Invite a new team member',
    } as DialogProps,
    Element: InviteNewTeamMemberModal,
  },
  [ModalName.SKIP_CONNECT_GATEWAY]: {
    name: ModalName.SKIP_CONNECT_GATEWAY,
    defaultProps: {
      maxWidth: 'md',
    } as DialogProps,
    Element: SkipConnectGatewayModal,
  },
  [ModalName.ONBOARDING_CONNECT_GATEWAY]: {
    name: ModalName.ONBOARDING_CONNECT_GATEWAY,
    defaultProps: {
      maxWidth: 'sm',
    } as DialogProps,
    Element: ConnectGatewayModal,
  },
};

// eslint-disable-next-line import/no-unused-modules
export const AssertConfig: {
  [Key in ModalName]: {
    name: ModalName;
    defaultProps: DialogProps;
  };
} = modalConfig;

export type ModalConfigType = typeof modalConfig;
