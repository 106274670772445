import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import React, {FC, useState} from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import {useNavigate} from 'react-router-dom';
import {useCustomModals} from '@local/frontend/libs/modals/useModals';
import {ModalName} from '@local/frontend/libs/modals/ModalName';

const SkipConnectGatewayModal: FC<React.PropsWithChildren<unknown>> = () => {
  const navigate = useNavigate();
  const {closeModal} = useCustomModals();
  const [acknowledgedTerms, setAcknowledgedTerms] = useState<boolean>(false);

  const userCapabilitiesAfterSkippingConnectGateway = {
    allowed: [
      'Integrate Hands In',
      'Invite team members',
      'View API keys & documentation',
    ],
    restricted: [
      'Create payments',
      'Receive customer or payment data',
      'Switch to live mode',
    ],
  };

  const onSubmit = () => {
    navigate('/dashboard/home', {replace: true});
    closeModal(ModalName.SKIP_CONNECT_GATEWAY);
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h4">
        Continue without connecting a processor
      </Typography>
      <Box
        sx={{
          p: 2,
          borderRadius: 1,
          backgroundColor: theme => theme.palette.grey[200],
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography
                sx={{fontWeight: 600, fontSize: 16, color: 'primary.dark'}}
              >
                You can still:
              </Typography>
              {userCapabilitiesAfterSkippingConnectGateway.allowed.map(
                allowedCapability => (
                  <Stack key={allowedCapability} spacing={1} direction="row">
                    <CheckCircleIcon sx={{color: 'success.dark'}} />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: 14,
                        color: 'primary.dark',
                      }}
                    >
                      {allowedCapability}
                    </Typography>
                  </Stack>
                )
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <Typography
                sx={{fontWeight: 600, fontSize: 16, color: 'primary.dark'}}
              >
                You will not be able to:
              </Typography>
              {userCapabilitiesAfterSkippingConnectGateway.restricted.map(
                restrictedCapability => (
                  <Stack key={restrictedCapability} spacing={1} direction="row">
                    <CancelIcon sx={{color: 'error.dark'}} />
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: 14,
                        color: 'primary.dark',
                      }}
                    >
                      {restrictedCapability}
                    </Typography>
                  </Stack>
                )
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Stack sx={{alignItems: {xs: 'flex-start', sm: 'flex-end'}}} spacing={1}>
        <FormControlLabel
          sx={{mr: 0}}
          control={
            <Checkbox
              checked={acknowledgedTerms}
              onChange={(_event, checked) => setAcknowledgedTerms(checked)}
            />
          }
          label="I acknowledge I will have limited access to the platform"
        />

        <Button
          onClick={onSubmit}
          sx={{width: {xs: '100%', sm: 'auto'}}}
          variant="contained"
          color="primary"
          disabled={!acknowledgedTerms}
        >
          Continue
        </Button>
      </Stack>
    </Stack>
  );
};

export default SkipConnectGatewayModal;
