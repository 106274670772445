import React, {FC} from 'react';
import Typography from '@mui/material/Typography';
import {GatewayDisplayNames} from '../../../@types/GatewayDisplayNames';

interface ShuttleDescriptionProps {
  isRedirect?: boolean;
  gatewayName: GatewayDisplayNames;
}

const ShuttleDescription: FC<
  React.PropsWithChildren<ShuttleDescriptionProps>
> = ({isRedirect, gatewayName}) => {
  if (isRedirect) {
    return (
      <Typography
        sx={{
          display: 'inline-flex',
          fontWeight: 500,
          width: '100%',
          alignSelf: 'flex-start',
        }}
      >
        After you click Connect, select {gatewayName} from the list of available
        processors. You will then be presented with connection instructions and
        redirected to {gatewayName}.
      </Typography>
    );
  }

  return (
    <Typography
      sx={{
        display: 'inline-flex',
        fontWeight: 500,
        width: '100%',
        alignSelf: 'flex-start',
      }}
    >
      After you click Connect, select {gatewayName} from the list of available
      processors. You will then need to provide your {gatewayName} account
      sandbox testing credentials in the relevant fields.
    </Typography>
  );
};

export default ShuttleDescription;
