import type {RouteObject} from 'react-router-dom';
import {Navigate, Outlet} from 'react-router-dom';
import React, {Suspense} from 'react';
import Loading from '@local/frontend/components/atoms/Loading';
import AnalyticsProvider from '@local/frontend/providers/AnalyticsProvider';
import PrivateMerchantRoute from '../../providers/PrivateMerchantRoute';
import NotificationProvider from '../../providers/NotificationProvider';
import DashboardLayout from '../../layouts/DashboardLayout';
import {homeRoutes} from './home/routes';
import {connectRoutes} from './connect/routes';
import {developerRoutes} from './developers/routes';
import {settingsRoutes} from './settings/routes';
import {groupPaymentsRoutes} from './group-payments/routes';
import {billingRoutes} from './billing/routes';
import NotFoundPage from '../error/NotFoundPage';
import {multiCardPaymentRoutes} from './multi-cards/routes';
import {paymentsRoutes} from './payments/routes';
import {customersRoutes} from './customers/routes';
import {paymentLinkRoutes} from './payment-links/routes';

export const dashboardRoutes: RouteObject = {
  path: 'dashboard',
  element: (
    <PrivateMerchantRoute>
      <AnalyticsProvider>
        <NotificationProvider>
          <DashboardLayout>
            <Suspense fallback={<Loading />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </NotificationProvider>
      </AnalyticsProvider>
    </PrivateMerchantRoute>
  ),
  children: [
    {index: true, element: <Navigate to="home" replace />},
    homeRoutes,
    connectRoutes,
    developerRoutes,
    settingsRoutes,
    groupPaymentsRoutes,
    billingRoutes,
    multiCardPaymentRoutes,
    paymentsRoutes,
    customersRoutes,
    paymentLinkRoutes,
    {
      path: '*',
      element: <NotFoundPage />,
    },
  ],
};
