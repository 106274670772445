export enum ModalName {
  REVIEW = 'REVIEW',
  REVIEW_CHECKOUT = 'REVIEW_CHECKOUT',
  REVIEW_CREATE_MULTI_CARD = 'REVIEW_CREATE_MULTI_CARD',
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
  ADD_GROUP_PAYMENT_CUSTOMER = 'ADD_GROUP_PAYMENT_CUSTOMER',
  EDIT_CUSTOMER = 'EDIT_CUSTOMER',
  REFUND_PAYMENT = 'REFUND_PAYMENT',
  CREATE_WEBHOOK = 'CREATE_WEBHOOK',
  UPDATE_WEBHOOK = 'UPDATE_WEBHOOK',
  BILLING_PLANS = 'BILLING_PLANS',
  CANCEL_BILLING_PLAN = 'CANCEL_BILLING_PLAN',
  ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD',
  UPDATE_BILLING_DETAILS = 'UPDATE_BILLING_DETAILS',
  INVITE_NEW_TEAM_MEMBER = 'INVITE_NEW_TEAM_MEMBER',
  // onboarding
  SKIP_CONNECT_GATEWAY = 'SKIP_CONNECT_GATEWAY',
  ONBOARDING_CONNECT_GATEWAY = 'ONBOARDING_CONNECT_GATEWAY',
}
