import React, {lazy} from 'react';
import {Navigate} from 'react-router-dom';

const OnboardingDetailsPage = lazy(
  () => import('./details/OnboardingDetailsPage')
);
const OnboardingConnectGatewayPage = lazy(
  () => import('./connect/OnboardingConnectGatewayPage')
);

export const onboardingRoutes = {
  path: 'onboarding',
  children: [
    {index: true, element: <Navigate to="details" replace />},
    {
      path: 'details',
      element: <OnboardingDetailsPage />,
    },
    {
      path: 'connect',
      element: <OnboardingConnectGatewayPage />,
    },
  ],
};
